

.result {
  padding: 15px;
  border-radius: 5px;
  list-style: none;
  box-shadow: 0 12px 9px -6px #d1d1d1;
  margin-bottom: 15px;
}
.date {
  text-align: center;
}
.myMillion {
  text-align: center;
  line-height: 40px;
  background: darkblue;
  color: aliceblue;
  display: block;
  width: 230px;
  margin: 15px auto auto;
}

.allNumbers {
  display: flex;
  text-align: center;
  justify-content: center;
}

.circle {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 1.5em;
  float: left; 
}

.number {
  background: #0050CC;
  color: aliceblue;
}

.star {
  background: gold;
  color: black;
}
body {
  font: 14px "Century Gothic", Futura, sans-serif;
  margin: 20px;
}

ol, ul {
  padding-left: 30px;
}

.board-row:after {
  clear: both;
  content: "";
  display: table;
}

.status {
  margin-bottom: 10px;
}

.square {
  background: #fff;
  border: 1px solid #999;
  float: left;
  font-size: 24px;
  font-weight: bold;
  line-height: 34px;
  height: 34px;
  margin-right: -1px;
  margin-top: -1px;
  padding: 0;
  text-align: center;
  width: 34px;
}

.square:focus {
  outline: none;
}

.kbd-navigation .square:focus {
  background: #ddd;
}

.game {
  display: flex;
  flex-direction: row;
}

.game-info {
  margin-left: 20px;
}

