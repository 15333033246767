.result {
  padding: 15px;
  border-radius: 5px;
  list-style: none;
  box-shadow: 0 12px 9px -6px #d1d1d1;
  margin-bottom: 15px;
}
.date {
  text-align: center;
}
.myMillion {
  text-align: center;
  line-height: 40px;
  background: darkblue;
  color: aliceblue;
  display: block;
  width: 230px;
  margin: 15px auto auto;
}

.allNumbers {
  display: flex;
  text-align: center;
  justify-content: center;
}

.circle {
  border-radius: 50%;
  width: 50px;
  height: 50px;
  line-height: 50px;
  text-align: center;
  font-size: 1.5em;
  float: left; 
}

.number {
  background: #0050CC;
  color: aliceblue;
}

.star {
  background: gold;
  color: black;
}